import React from 'react';
import logo from './logo.svg';
import './App.scss';
import axios from 'axios';


interface Card {
  id:number,
  description:string,
  number:number,
  imageUrl:string
}

function App() {

  const [selectedCards, setSelectedCards] = React.useState<Card[]>([]);

  const [location, setLocation] = React.useState<string>('');
  

  const cards:Card[] = [
    {
      "id": 1,
      "description": "I – Panfilio",
      "number" : 1,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/9fcb32b8-0254-4c0a-442e-20f3464a2600/tokenhalf"
    },
    {
      "id": 2,
      "description": "II – Postumio",
      "number" : 2,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/a93ae9b9-8596-4deb-b5ab-00871e7e8b00/tokenhalf"
    },
    {
      "id": 3,
      "description": "III – Lenpio",
      "number" : 3,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/95b50730-01e9-4682-f346-a82bff088f00/tokenhalf"
    },
    {
      "id": 4,
      "description": "IV – Mario",
      "number" : 4,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/925b8d38-d623-4866-1bf2-08ea4d849400/tokenhalf"
    },
    {
      "id": 5,
      "description": "V – Catulo",
      "number" : 5,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/d7758e65-7dc3-4045-e039-39536fde3a00/tokenhalf"
    },
    {
      "id": 6,
      "description": "VI – Sesto",
      "number" : 6,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/15848417-84f2-41a4-da81-8b537521ae00/tokenhalf"
    },
    {
      "id": 7,
      "description": "VII – Deo Tauro",
      "number" : 7,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/e17bbaac-6223-427b-7cd7-a900c2068900/tokenhalf"
    },
    {
      "id": 8,
      "description": "VIII – Nerone",
      "number" : 8,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/bb0c2928-7c02-4db6-7525-b06ad0b62c00/tokenhalf"
    },
    {
      "id": 9,
      "description": "IX – Falco",
      "number" : 9,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/68a7a22f-c4ef-4270-0793-ac64ddc0cd00/tokenhalf"
    },
    {
      "id": 10,
      "description": "X – Venturio",
      "number" : 10,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/e5940d7f-4811-49ea-2e75-1c79e2975100/tokenhalf"
    },
    {
      "id": 11,
      "description": "XI – Tulio",
      "number" : 11,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/ffb89617-284f-4422-559d-be576619b100/tokenhalf"
    },
    {
      "id": 12,
      "description": "XII – Carbone",
      "number" : 12,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/cb11365c-8246-4b61-15ed-d36428103900/tokenhalf"
    },
    {
      "id": 13,
      "description": "XIII – Catone",
      "number" : 13,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/c376599d-f231-4910-5ba6-f672b02ca800/tokenhalf"
    },
    {
      "id": 14,
      "description": "XIIII – Bocho",
      "number" : 14,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/b9624920-8b51-4783-0b8e-4b044a3c3b00/tokenhalf"
    },
    {
      "id": 15,
      "description": "XV – Metelo",
      "number" : 15,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/577da6fe-96aa-426c-758f-9f1617651b00/tokenhalf"
    },
    {
      "id": 16,
      "description": "XVI – Olivo",
      "number" : 16,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/588fb414-6eb0-41c6-d4b7-c3ca01d81400/tokenhalf"
    },
    {
      "id": 17,
      "description": "XVII – Ipeo",
      "number" : 17,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/39d7ae3f-5c59-47fb-a804-1c351125a600/tokenhalf"
    },
    {
      "id": 18,
      "description": "XVIII – Lentulo",
      "number" : 18,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/d5f06b62-e844-4970-5d71-459ab4fd7d00/tokenhalf"
    },
    {
      "id": 19,
      "description": "XIX – Sabino",
      "number" : 19,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/41d3a8c9-3f6e-4cc4-d804-916dcef14000/tokenhalf"
    },
    {
      "id": 20,
      "description": "XX – Nenbroto",
      "number" : 20,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/36eb6d85-95c4-47b5-227c-933525078600/tokenhalf"
    },
    {
      "id": 21,
      "description": "XXI – Nabuchodenasor",
      "number" : 21,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/43e6f762-4c28-4d42-b880-4766457c3f00/tokenhalf"
    },
    {
      "id": 22,
      "description": "Ace of Cups",
      "number" : 1,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/9ffafb14-160c-42e2-74b8-7e2689f13500/tokenhalf"
    },
    {
      "id": 23,
      "description": "Two of Cups",
      "number" : 2,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/a2a2c99f-1890-4a7e-45fe-1336422db600/tokenhalf"
    },
    {
      "id": 24,
      "description": "Three of Cups",
      "number" : 3,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/53d712b7-b64f-4a62-a7e3-8981fff3df00/tokenhalf"
    },
    {
      "id": 25,
      "description": "Four of Cups",
      "number" : 4,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/7e223f15-ea7f-4f19-bcf1-617339102b00/tokenhalf"
    },
    {
      "id": 26,
      "description": "Five of Cups",
      "number" : 5,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/88eff035-e2b4-477d-b871-5c6ce9888800/tokenhalf"
    },
    {
      "id": 27,
      "description": "Six of Cups",
      "number" : 6,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/38b3b675-aaf8-4f7e-44aa-6f08be823800/tokenhalf"
    },
    {
      "id": 28,
      "description": "Seven of Cups",
      "number" : 7,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/6f36111a-58ab-438e-3014-ca74726d9300/tokenhalf"
    },
    {
      "id": 29,
      "description": "Eight of Cups",
      "number" : 8,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/05a009a5-344c-499a-2775-ef1d95ee3b00/tokenhalf"
    },
    {
      "id": 30,
      "description": "Nine of Cups",
      "number" : 9,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/3bc6b491-9339-402b-1ade-3a65f062a500/tokenhalf"
    },
    {
      "id": 31,
      "description": "Ten of Cups",
      "number" : 10,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/5437244d-575b-453f-fa08-43347eb7b400/tokenhalf"
    },
    {
      "id": 32,
      "description": "Page of Cups",
      "number" : 11,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/9104c85d-8e70-4757-9b89-db9990e8f100/tokenhalf"
    },
    {
      "id": 33,
      "description": "Knight of Cups",
      "number" : 12,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/87ff01e5-3a80-460c-279c-7ce72f5fb500/tokenhalf"
    },
    {
      "id": 34,
      "description": "Queen of Cups",
      "number" : 13,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/acb70926-b4f5-46ec-7e84-9e7eadcd7400/tokenhalf"
    },
    {
      "id": 35,
      "description": "King of Cups",
      "number" : 14,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/e4ecc208-f98a-4b92-9f87-8f3c878d4800/tokenhalf"
    },
    {
      "id": 36,
      "description": "Ace of Coins",
      "number" : 1,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/ee1accc6-310e-4377-bf97-f742c6c3a000/tokenhalf"
    },
    {
      "id": 37,
      "description": "Two of Coins",
      "number" : 2,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/9c1af28e-0936-4ed1-3d7a-4a5ccdb71b00/tokenhalf"
    },
    {
      "id": 38,
      "description": "Three of Coins",
      "number" : 3,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/ccb40c96-7750-46e9-ac00-d78ed2ab6a00/tokenhalf"
    },
    {
      "id": 39,
      "description": "Four of Coins",
      "number" : 4,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/3a120ddd-c682-4e4f-0ff1-0c9d32d75c00/tokenhalf"
    },
    {
      "id": 40,
      "description": "Five of Coins",
      "number" : 5,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/30f1292a-ac22-4c15-c1c8-315d01619900/tokenhalf"
    },
    {
      "id": 41,
      "description": "Six of Coins",
      "number" : 6,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/d8bcbcde-5917-4981-bc04-ae2b9da34200/tokenhalf"
    },
    {
      "id": 42,
      "description": "Seven of Coins",
      "number" : 7,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/597e8bf6-b2f0-4882-4c51-561e5f761f00/tokenhalf"
    },
    {
      "id": 43,
      "description": "Eight of Coins",
      "number" : 8,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/b042b04b-c441-4011-0703-5cf0129c9700/tokenhalf"
    },
    {
      "id": 44,
      "description": "Nine of Coins",
      "number" : 9,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/74a5a9f0-d2e6-4138-1f9c-72ea2b63b200/tokenhalf"
    },
    {
      "id": 45,
      "description": "Ten of Coins",
      "number" : 10,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/24414694-d6cd-4473-5913-94b891501d00/tokenhalf"
    },
    {
      "id": 46,
      "description": "Page of Coins",
      "number" : 11,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/3677b58d-c8bd-414d-e624-1d4d01f87500/tokenhalf"
    },
    {
      "id": 47,
      "description": "Knight of Coins",
      "number" : 12,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/a13e1f8b-fc73-47f5-4e1d-22d4c503d900/tokenhalf"
    },
    {
      "id": 48,
      "description": "Queen of Coins",
      "number" : 13,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/cb9abacf-bab8-44dc-3091-a309b6001c00/tokenhalf"
    },
    {
      "id": 49,
      "description": "King of Coins",
      "number" : 14,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/cfed1e53-b6bb-4a5d-bec6-7770bd0dcb00/tokenhalf"
    },
    {
      "id": 50,
      "description": "Ace of Wands",
      "number" : 1,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/5c952bda-6c3a-488a-6b09-d1d0a7079c00/tokenhalf"
    },
    {
      "id": 51,
      "description": "Two of Wands",
      "number" : 2,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/94b94d4d-0c06-4543-c2a0-1cbbd8010800/tokenhalf"
    },
    {
      "id": 52,
      "description": "Three of Wands",
      "number" : 3,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/815dcd5c-efa3-4506-3eca-4eab8dc57b00/tokenhalf"
    },
    {
      "id": 53,
      "description": "Four of Wands",
      "number" : 4,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/fdbdbbd9-8ecc-4b0f-db76-4ccbaeeb4e00/tokenhalf"
    },
    {
      "id": 54,
      "description": "Five of Wands",
      "number" : 5,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/5e7230fa-dd4d-4818-9e94-a042d2645000/tokenhalf"
    },
    {
      "id": 55,
      "description": "Six of Wands",
      "number" : 6,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/edc66609-f333-4924-65f8-ab2897d5a200/tokenhalf"
    },
    {
      "id": 56,
      "description": "Seven of Wands",
      "number" : 7,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/6a7da848-52af-4592-6a04-c49b83fe5d00/tokenhalf"
    },
    {
      "id": 57,
      "description": "Eight of Wands",
      "number" : 8,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/e1253c14-f88c-4ec5-deda-f95e6ce5f900/tokenhalf"
    },
    {
      "id": 58,
      "description": "Nine of Wands",
      "number" : 9,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/2157e2cb-c994-44c3-db00-0b73a1a13f00/tokenhalf"
    },
    {
      "id": 59,
      "description": "Ten of Wands",
      "number" : 10,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/124033be-a649-4cc1-c26b-55ec47387700/tokenhalf"
    },
    {
      "id": 60,
      "description": "Page of Wands",
      "number" : 11,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/c62e6b16-b313-4921-93f0-b01fd0c49800/tokenhalf"
    },
    {
      "id": 61,
      "description": "Knight of Wands",
      "number" : 12,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/347c3a2e-276f-45ae-3e43-089adad9fa00/tokenhalf"
    },
    {
      "id": 62,
      "description": "Queen of Wands",
      "number" : 13,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/7ede00c0-02a8-4e92-a1fb-6c32fe977400/tokenhalf"
    },
    {
      "id": 63,
      "description": "King of Wands",
      "number" : 14,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/212a155f-568c-4746-9854-3cf6d219c100/tokenhalf"
    },
    {
      "id": 64,
      "description": "Ace of Swords",
      "number" : 1,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/8abe9401-91b7-4f7c-6080-1e2420045400/tokenhalf"
    },
    {
      "id": 65,
      "description": "Two of Swords",
      "number" : 2,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/336a4fdb-17b1-4ce1-e700-29775668ab00/tokenhalf"
    },
    {
      "id": 66,
      "description": "Three of Swords",
      "number" : 3,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/eaac43c3-1b70-4066-5705-4275d47cd200/tokenhalf"
    },
    {
      "id": 67,
      "description": "Four of Swords",
      "number" : 4,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/c84d456a-6dfa-48f8-d332-2bbc0079cc00/tokenhalf"
    },
    {
      "id": 68,
      "description": "Five of Swords",
      "number" : 5,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/a6cfa656-ebc5-40cc-641d-0bc8658dfe00/tokenhalf"
    },
    {
      "id": 69,
      "description": "Six of Swords",
      "number" : 6,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/803d26bd-96eb-45db-0336-d666a0889d00/tokenhalf"
    },
    {
      "id": 70,
      "description": "Seven of Swords",
      "number" : 7,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/69273e8e-d85b-49bb-24b2-06f586c7b700/tokenhalf"
    },
    {
      "id": 71,
      "description": "Eight of Swords",
      "number" : 8,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/b610454f-a8cd-4a40-dcee-5a39478b1e00/tokenhalf"
    },
    {
      "id": 72,
      "description": "Nine of Swords",
      "number" : 9,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/bf0dec54-8dc2-4045-a424-a8ae42b59100/tokenhalf"
    },
    {
      "id": 73,
      "description": "Ten of Swords",
      "number" : 10,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/d92df9e1-1051-4a8b-b4b0-2bb08b70f200/tokenhalf"
    },
    {
      "id": 74,
      "description": "Page of Swords",
      "number" : 11,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/a3c946a3-de02-4949-8eab-1610db956b00/tokenhalf"
    },
    {
      "id": 75,
      "description": "Knight of Swords",
      "number" : 12,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/470839c6-8905-447d-592f-fb90e9ff3700/tokenhalf"
    },
    {
      "id": 76,
      "description": "Queen of Swords",
      "number" : 13,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/503e2c8d-162a-4843-13d6-789270c60a00/tokenhalf"
    },
    {
      "id": 77,
      "description": "King of Swords",
      "number" : 14,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/e4e272b5-8699-492d-fbce-d7d5c9020200/tokenhalf"
    },
    {
      "id": 78,
      "description": "0 – Mato",
      "number" : 0,
      "imageUrl": "https://imagedelivery.net/9typBgc0Q1fSax5-Uwa3XA/aad81155-0f12-4a45-0710-f55d31812500/tokenhalf"
    }
  ]

  function shuffle(array:any[]) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  const [displayCards, setDisplayCards] = React.useState<Card[]>(shuffle(cards));

  function cardSelected(selectedCard:Card) {
    let selectedCardInList = selectedCards.filter((card) => card.id === selectedCard.id)[0];
    let updatedCards = selectedCards;
    
    if (selectedCardInList) {
      updatedCards = selectedCards.filter((card) => card.id !== selectedCard.id);
      setSelectedCards([... updatedCards]);
    } else {
      updatedCards.push(selectedCard);
      setSelectedCards([... updatedCards]);
    }
  }

  function getCardList():string {
      let list = '';
      for (let i = 0; i < selectedCards.length; i++) {
        list = list + selectedCards[i].number;
      }
      return list;
  }

    React.useEffect(() => {
      if (selectedCards.length > 0) {
        selectCard();
      }
        
    }, [selectedCards]);

    React.useEffect(() => {
      if(location.length > 0) {
        window.location.href = location;
      }
        
    }, [location]);


  function selectCard() {
    axios({
      "method": "POST",
      "url": `${process.env.REACT_APP_SERVICE_BASEPATH}/sola-busca/select`,
      "headers": {
          'content-type': 'application/json'
      },
      "data": getCardList()
  
  })
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse) {
          setLocation(serviceResponse);
        }
          
          
      })
      .catch((error) => {
          console.log(error);
          
      });
  }


  return (
    <div className="App">
      <div className='cards'>
        <ul>
          {displayCards.map((card) => <li key={card.id}>
            <div className={'image-frame ' + (selectedCards.some(cardItem => cardItem.id === card.id) ? 'selected' : '')} onClick={() => cardSelected(card)}>

              <img src={card.imageUrl} />
              <div className="name-plate">{card.description}</div>
            </div>
          </li>)}
        </ul>
      </div>
    </div>
  );
}

export default App;
